import React from 'react';
import { MultipleAnswer, TextItem, QuestionAnswer, Likert, OpenQuestion, Scale, Video, ItemConvergence, Number} from './'

function CourceMainContent({ item, index, setFieldValue, showQuestion, isEditableForm, studentSections, showConvergence, showDisableConvergence, values, showFeedback, section }) {
    return (
        <>
            {item.typeItem.typeItem === 'closed answer question' && showQuestion && 
                <QuestionAnswer item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} values={values} section={section}/>
            }
            {item.typeItem.typeItem === 'multiple answer' && showQuestion && 
                <MultipleAnswer item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} values={values} section={section}/>
            }
            {item.typeItem.typeItem === 'text' && showQuestion && 
                <TextItem item={item}  />
            }
            {item.typeItem.typeItem === 'video' && showQuestion && 
                <Video item={item} />
            }
            {item.typeItem.typeItem === 'likert' && showQuestion && 
                <Likert item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} values={values}/>
            }
            {item.typeItem.typeItem === 'likert_muto' && showQuestion && 
                <Likert item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} values={values} muto="1"/>
            }
            {item.typeItem.typeItem === 'open question' && showQuestion && 
                <OpenQuestion item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} values={values}/>
            }
            {item.typeItem.typeItem === 'number' && showQuestion && 
                <Number item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} values={values}/>
            }
            {item.typeItem.typeItem === 'scale' && showQuestion && 
                <Scale item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} values={values}/>
            }
            {(item.expected_convergence === 1 || item.expected_feedback === 1) && showQuestion && (showConvergence || showDisableConvergence || showFeedback) && 
                <ItemConvergence item={item} index={index} setFieldValue={setFieldValue} isEditableForm={isEditableForm} studentSections={studentSections} showDisableConvergence={showDisableConvergence} showFeedback={showFeedback}/>
            }
        </>
    );
}

export { CourceMainContent };