import React, { Component } from 'react';
import { connect, useDispatch} from 'react-redux';
import { schoolActions, showAlert } from '../../../_actions';
import Header from '../Shared/Header';
import { Row, Col, Accordion, Card, Button, Table } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
// import './Schools.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import { relativeTimeThreshold } from 'moment';
  
class Schools extends Component {

  constructor(props) {
    super(props);
    this.state = {
      schoolData: null,
    };
  }

  componentWillMount() {
    this.props.getSchools();
  }

  handleUpdate = async () => {
    if(this.state.schoolId && this.state.school)
    {
      await CallApi(this.props.updateSchool, [this.state.schoolId, this.state.school]);
        this.setState({school: ''});
        this.setState({schoolId: ''});
        this.setState({showUpdate: false});
        this.props.getSchools();
    }
  }

  handleSubmit = async () => {
    await CallApi(this.props.newSchool, [this.state.school]);
    this.setState({school: ''});
    this.setState({showNew: false});
    this.props.getSchools();
  }

  handleSubmitTeacher = async () => {
    if(this.state.teacherId) {
      await CallApi(this.props.updateTeacher, [this.state.teacherId, this.state.name, this.state.surname, this.state.email]);
    } else {
      await CallApi(this.props.newTeacher, [this.state.schoolId, this.state.name, this.state.surname, this.state.email]);
    }
    this.setState({teacherId: ''});
    this.setState({name: ''});
    this.setState({surname: ''});
    this.setState({email: ''});
    this.setState({showNewTeacher: false});
  }

  handleDelete = async (schoolData) => {
    if (window.confirm("Confermi la cancellazione della scuola con le relative classi?")) {
      await CallApi(this.props.deleteSchool, [schoolData.schoolId]);
      this.props.getSchools();
    }
  }

  handleDeleteTeacher = async (teacherId, schoolData) => {
    if (window.confirm("Confermi la cancellazione dell'insegnante e delle relative classi a cui è assegnato?")) {
      await CallApi(this.props.deleteTeacher, [teacherId]);
      this.props.getSchools();
    }
  }

  discardForm() {
    this.setState({school: ''})
    this.setState({schoolId: ''})
    this.setState({showNew: false})
    this.setState({showUpdate: false})
    this.setState({showNewTeacher: false})
    this.setState({showUpdateTeacher: false})
  }

  SetUpdateSchool(schoolData) {
    this.setState({school: schoolData.school});
    this.setState({schoolId: schoolData.schoolId});
    this.setState({showNew: false}); 
    this.setState({showUpdate: true});
  }
  SetNewSchool () {
    this.setState({school: ''})
    this.setState({schoolId: ''})
    this.setState({showNew: true}); 
    this.setState({showUpdate: false});
  }

  SetNewTeacher (schoolId) {
    this.setState({schoolId: schoolId});
    this.setState({showNew: false}); 
    this.setState({showUpdate: false});
    this.setState({showNewTeacher: true});
  }

  SetUpdateTeacher(teacherData) {
    this.setState({schoolId: teacherData.schoolId});
    this.setState({teacherId: teacherData.teacherId});
    this.setState({name: teacherData.name});
    this.setState({surname: teacherData.surname});
    this.setState({email: teacherData.email});
    this.setState({showNew: false}); 
    this.setState({showUpdate: false});
    this.setState({showNewTeacher: true});
  }

  render() {
    const schools = this.props.schools;
    return (
      <div className="teacher-dash-main">
        <Header />
        <div className="teacher-dash-inner">
          <div className="teacher-classes-main">
            <h1 className="pg-ttl" style={{textAlign: "center"}}>SCUOLE</h1>
            {this.state.showNew ? (
              <div style={{"height":"160px"}}>
                <Formik >
                  <Form>
                  <div className="form-group">
                    <label className="form-label"><b>Nome della scuola</b></label>
                    <Field type="text" required={[true, 'Inserisci il nome della scuola']} name="school" className="form-control" defaultValue={this.state.school} onChange={(event) => {this.setState({school: event.target.value});}}/>
                    <button className="btn btn-reset d-inline-block" style={{marginRight: '3px', lineHeight: '2em', marginTop: '5px' }} onClick={this.discardForm.bind(this)}>Annulla</button>
                    <button className="btn btn-default d-inline-block" style={{lineHeight: '2em', marginTop: '5px' }} onClick={this.handleSubmit.bind(this)}>{this.props.loading && <span className="spinner-border spinner-border-sm mr-1"></span>}Salva</button>
                  </div>
                  </Form>
                </Formik>
              </div>
            ) : (
              <div>
                <button className="btn-default" style={{lineHeight: '2em'}} onClick={(event) => { this.SetNewSchool();}}>Inserisci nuova scuola</button><br/>
            <br/>
              </div>
            )
            }
            <Accordion defaultActiveKey="0">
              {schools.items && schools.items.schools && 
                <>
                    {schools.items.schools.map((schoolData, index) =>
                      <Card className="acc-cls-main" key={index}>
                        <Card.Header>
                          <Row>
                            <Col>
                              <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
                                {schoolData.school} 
                              </Accordion.Toggle>
                            </Col>
                            <Col>
                              <ul className="dcvc-btn">
                                  <li style={{fontFamily: 'Fira Code', fontSize: '12px', cursor: 'pointer'}} onClick={(event) => {this.SetUpdateSchool(schoolData);}}><img src="/images/edit-icon.png" alt="view" className='img-fluid'/> Modifica</li>
                                  <li style={{fontFamily: 'Fira Code', fontSize: '12px', cursor: 'pointer'}} onClick={(event) => {this.handleDelete(schoolData);}}><img src="/images/delete-icon.png" alt="view" className='img-fluid'/> Cancella</li>
                                  <li></li>
                                  <li><Link to={`/researcher/classes-page/${schoolData.schoolId}`}><img src="/view-icon.png" alt="view" className='img-fluid'/> Visualizza le classi</Link></li>
                              </ul>
                            </Col>
                          </Row>
                        </Card.Header>
                        <Accordion.Collapse eventKey={`${index}`}>
                          <>
                          <div>
                        { (this.state.showUpdate && this.state.schoolId == schoolData.schoolId) && (
                          <Formik >
                            <Form>
                            <div className="form-group">
                              <label className="form-label"><b>Nome della scuola</b></label>
                              <Field type="text" required={[true, 'Inserisci il nome della scuola']} name="school" className="form-control" defaultValue={this.state.school} onChange={(event) => {this.setState({school: event.target.value});}}/>
                            </div>
                            <div className="form-group">
                              <Row className="tchr-exam-row0">
                                <Col xs={12} sm={12} className="text-right">
                                  <>
                                    <button className="btn btn-reset d-inline-block" style={{marginRight: '3px' }} onClick={this.discardForm.bind(this)}>Annulla</button>
                                    <button className="btn btn-default d-inline-block" onClick={this.handleUpdate.bind(this)}>{this.props.loading && <span className="spinner-border spinner-border-sm mr-1"></span>}Salva</button>
                                  </>
                                </Col>
                              </Row>
                            </div>
                            </Form>
                          </Formik>
                        )}
                          { (this.state.showNewTeacher && this.state.schoolId == schoolData.schoolId) && (
                            <div style={{"height":"300px"}}>
                            <Formik >
                              <Form>
                              <div className="form-group">
                                <label className="form-label"><b>DATI DELL'INSEGNANTE</b></label><br/>
                                <label className="form-label"><b>Nome</b></label>
                                <Field type="text" required={[true, 'Inserisci il nome dell\'insegnante']} name="name" className="form-control" defaultValue={this.state.name} onChange={(event) => {this.setState({name: event.target.value});}}/>
                                <label className="form-label"><b>Cognome</b></label>
                                <Field type="text" required={[true, 'Inserisci il cognome dell\'insegnante']} name="surname" className="form-control" defaultValue={this.state.surname} onChange={(event) => {this.setState({surname: event.target.value});}}/>
                                <label className="form-label"><b>Email</b></label>
                                <Field type="text" required={[true, 'Inserisci l\'email dell\'insegnante']} name="email" className="form-control" defaultValue={this.state.email} onChange={(event) => {this.setState({email: event.target.value});}}/>
                                <button className="btn btn-reset d-inline-block" style={{marginRight: '3px', lineHeight: '2em', marginTop: '5px' }} onClick={this.discardForm.bind(this)}>Annulla</button>
                                <button className="btn btn-default d-inline-block" style={{lineHeight: '2em', marginTop: '5px' }} onClick={this.handleSubmitTeacher.bind(this)}>{this.props.loading && <span className="spinner-border spinner-border-sm mr-1"></span>}Salva</button>
                              </div>
                              </Form>
                            </Formik>
                            </div>
                          )}
                           <h5> <b>INSEGNANTI</b> {!this.state.showNewTeacher && <img src="/images/add-icon.png" alt="Aggiungi insegnante" style={{cursor: 'pointer'}} className='img-fluid' onClick={(event)=> {this.SetNewTeacher(schoolData.schoolId);}}/>}</h5>
                          </div>
                          <Table responsive bordered>
                            <tbody>
                              {schoolData.teachers.map((teacherData, ind) =>
                                <tr key={ind}>
                                  <td>{teacherData.surname} {teacherData.name}</td>
                                  <td>{teacherData.email}</td>
                                  <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={(event) => {this.SetUpdateTeacher(teacherData);}}><img src="/images/edit-icon.png" alt="modifica" className='img-fluid'/></td>
                                  <td style={{textAlign: 'center',cursor: 'pointer'}} onClick={(event) => {this.handleDeleteTeacher(teacherData.teacherId, schoolData);}}><img src="/images/delete-icon.png" alt="cancella" className='img-fluid'/></td>
                                </tr>
                              )}
                              {! schoolData.teachers.length && (
                                <tr><td>Nessun insegnante inserito</td></tr>
                              )}
                            </tbody>
                          </Table>
                          </>
                        </Accordion.Collapse>
                      </Card>
                    )}
                </>
              }
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}

const CallApi = async (cb, params) => {
  await cb(...params);
}

const mapStateToProps = state => ({
  schools: state.schools,
  user: state.userAuth.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    getSchools: () => dispatch(schoolActions.getSchools()),
    newSchool: (school) => dispatch(schoolActions.newSchool(school)),
    updateSchool: (schoolId, school) => dispatch(schoolActions.updateSchool(schoolId, school)),
    deleteSchool: (schoolId) => dispatch(schoolActions.deleteSchool(schoolId)),
    newTeacher: (schoolId, name, surname, email) => dispatch(schoolActions.newTeacher(schoolId, name, surname, email)),
    updateTeacher: (teacherId,  name, surname, email) => dispatch(schoolActions.updateTeacher(teacherId, name, surname, email)),
    deleteTeacher: (teacherId) => dispatch(schoolActions.deleteTeacher(teacherId)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Schools)
