import React from 'react';
import { Field } from "formik";
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function SectionConvergence({ section, showDisableConvergence, studentSections, loading, showFeedback }) {
    const user = useSelector(state => state.userAuth.user);
    const isSelectedAns = (value, sectionId) => {
      if(studentSections && studentSections[0] && studentSections[0].convergence)
      {
          let isMatch = false;
          if(studentSections[0].sectionId === sectionId && studentSections[0].convergence === value)
          {
              isMatch = true;
          }
          return isMatch;
      }
      return false;
    }

    const getText = (sectionId) => {
      if(studentSections && studentSections[0] && studentSections[0].comment)
      {
          let text = '';
          if(studentSections[0].sectionId === sectionId)
          {
              text = studentSections[0].comment;
          }
          return text;
      }
      return '';
    }

    const getFeedback = (sectionId) => {
      if(studentSections && studentSections[0] && studentSections[0].feedback)
      {
          let text = false;
          if(studentSections[0].sectionId === sectionId)
          {
              text = studentSections[0].feedback;
          }
          return text;
      }
      return false;
    }

    return (
        <>
          {(section.expected_convergence === 1 || (section.expected_feedback === 1 && user)) && 
            <div className="student-course-row3 ssa-main">
              <div className="student-course-like student-course-rating">
                <h6 style={{textAlign: "left"}}>Autovalutazione dello Studente</h6>
                <div style={{textAlign: "left"}}>Adesso che hai letto le risposte del tuo collega più esperto, prova valutare le tue risposte confrontandole con le sue. Secondo te, le vostre risposte sono simili? 
                <br/>Indica da 1 (per niente simili) a 5 (completamente simili). A seguito commenta e giustifica il punteggio da te dato nell’autovalutazione: di quanto divergeva il tuo punteggio da quello dell’esperto e perché?</div>
                {section.expected_convergence === 1  && !user && 
                  <div className="student-likebtn-row">
                    <div className="student-likebtn-col">
                      <Row className="btn-border-draw">
                        <Col></Col>
                        {[...Array(5)].map((x, i) =>
                          <Col key={`section_con_${section.sectionId}_${i+1}`}>
                            <div className="likebtn-radio">
                              {!showDisableConvergence &&
                                <Field type="radio" id={`section_con_${section.sectionId}_${i+1}`} name={`section.convergence`} value={`${i+1}`} required/>
                              }
                              {showDisableConvergence &&
                                <Field type="radio" id={`section_con_${section.sectionId}_${i+1}`} name={`section.convergence`} value={`${i+1}`} checked={(isSelectedAns((i+1), section.sectionId))}/>
                              }                                            
                              <label htmlFor={`section_con_${section.sectionId}_${i+1}`} className="radio-label"></label>
                            </div>
                          </Col>
                        )}
                        <Col></Col>
                      </Row>
                      <Row className="btn-border-text">
                        <Col></Col>
                        {[...Array(5)].map((x, i) =>
                            <Col key={`con_label_${section.sectionId}_${i+1}`}>
                                <label>{i+1}</label>
                            </Col>
                        )}
                        <Col></Col>
                      </Row>
                    </div>
                  </div>
                }
                {section.expected_convergence === 1 && user && user.role.role && (user.role.role === "Teacher" || user.role.role === "Researcher") &&
                  <div className="stdn-rtng-comment">
                    <h4>Convergenza
                    {[...Array(10)].map((x, i) =>
                      <React.Fragment key={i}>
                        {(isSelectedAns((i+1), section.sectionId)) && 
                            <span>{(i+1)}</span>
                        }
                      </React.Fragment>
                    )}
                    </h4>
                    <p>{getText(section.sectionId)}</p>
                  </div>
                }
              </div>
              {section.expected_convergence === 1 && !user && 
                <div className="form-group">
                  <label className="frm-lbl">Commenta qua il punteggio da te assegnato</label>
                  {!showDisableConvergence &&
                      <Field as="textarea" className="form-control" name={`section.comment`}  required></Field>
                  }
                  {showDisableConvergence &&
                      <Field as="textarea" className="form-control" name={`section.comment`} value={`${getText(section.sectionId)}`}></Field>
                  }
                </div>
              }
              {user && user.role.role && (user.role.role === "Teacher") && section.expected_feedback === 1 && (showFeedback || getFeedback(section.sectionId)) &&
                  <div className="form-group">
                      <label className="frm-lbl">FEEDBACK</label>
                      {!showFeedback && getFeedback(section.sectionId) && 
                        <Field as="textarea" className="form-control" value={`${getFeedback(section.sectionId)}`} name={`section.feedback`}  disabled></Field>
                      }
                      {showFeedback && 
                        <Field as="textarea" className="form-control" name={`section.feedback`}  required></Field>
                      }
                  </div>
              }
              {((user && user.role.role && (user.role.role === "Researcher"))) && section.expected_feedback === 1 && getFeedback(section.sectionId) &&
                    <div className="stdn-rtng-comment">
                        <h4>FEEDBACK</h4>
                        <p>{getFeedback(section.sectionId)}</p>
                    </div>
                }
              {(!showDisableConvergence) &&
                <div style={{textAlign: "right"}}>
                  <button type="submit" disabled={(loading) ? true : false}>{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}INVIA</button>
                </div>
              }
            </div>
          }
        </>
    );
}

export { SectionConvergence };
