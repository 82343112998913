import React, { useEffect } from 'react';
import './MenuInterface.css';
import { menuActions } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import Header from '../Shared/Header';
import { SubMenuLink } from '../../../_components';
  
function MenuInterface() {
    const menu = useSelector(state => state.menu);
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const now = Moment().format('YYYY-MM-DD');

    useEffect(() => {
      dispatch(menuActions.getStudentMenu(user.studentId, user.classId));
    }, [user.studentId, user.classId, dispatch]);

    return (
      <div className="student-dash-main">
        <Header />
        <div className="student-dash-inner">
          {menu.loading && 
            <>
              <em>Loading menu...</em>
            </>
          }
          {menu.items &&
            <>
              {menu.items.course && 
                <h1 className="pg-ttl">{menu.items.course}</h1>
              }
              <div className="stumenu-main">
                {menu.items.menu && menu.items.menu.map((menuItem, index) =>
                  <div key={menuItem.sectionId} className={`stumenu-col stumenu-col${(((index+1)%4) === 0) ? 4 : ((index+1)%4)}`}>
                    <div className="stumenu-icon">
                      <img src={`/images/section_${menuItem.sectionId}.svg`} alt={menuItem.section}/>
                    </div>
                    <div className="stumenu-cont">
                      <h4>{menuItem.section}</h4>
                      {menuItem.scheduledOpening &&
                        <>
                          {(now < menuItem.scheduledOpening) && 
                            <>
                              <p>Disponibile dal {Moment(menuItem.scheduledOpening).format('DD/MM/YYYY')}</p>
                            </>
                          }
                          {(now >= menuItem.scheduledOpening) && 
                            <>
                              <p>Disponibile {Moment(menuItem.scheduledOpening).format('DD/MM/YYYY')}</p>
                            </>
                          }
                        </>
                      }
                      {menuItem.secondLevel &&
                        <>
                          {menuItem.secondLevel.map((secondLevelItem, ind) =>
                            <SubMenuLink key={ind} MainMenuId={menuItem.sectionId} MainMenuOpeningDate={menuItem.scheduledOpening} SubMenuId={secondLevelItem.sectionId} SectionName={secondLevelItem.section}/>
                          )}
                        </>
                      }
                    </div>
                  </div>
                )}
                {!menu.items.menu && 
                  <div className="stumenu-main">
                    <div className="stumenu-cont"><p>Nessuna sezione disponibile al momento</p></div>
                  </div>
                }
              </div>
            </>
          }
        </div>
      </div>
    );
}
export default MenuInterface;
