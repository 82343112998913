import { userAuthHeader } from '../_helpers';
import { userService } from '.';
const API_URL = process.env.REACT_APP_API_URL
export const schoolService = {
    getSchools,
    getSchoolDetail,
    newSchool,
    updateSchool,
    deleteSchool,
    newTeacher,
    updateTeacher,
    deleteTeacher
};

function getSchools() {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/get-schools`, requestOptions)
        .then(handleResponse)
        .then(schools => {
            return schools;
        });
}

function getSchoolDetail(schoolId) {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/get-school-by-id?schoolId=${schoolId}`, requestOptions)
        .then(handleResponse)
        .then(school => {
            return school;
        });
}

function newSchool(school) {
    const requestOptions = {
        method: 'POST',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/new-school?school=${school}`, requestOptions)
        .then(handleResponse)
        .then(school => {
            return school;
        });
}

function deleteSchool(schoolId) {
    const requestOptions = {
        method: 'DELETE',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/delete-school?schoolId=${schoolId}`, requestOptions)
        .then(handleResponse)
        .then(school => {
            return school;
        });
}

function updateSchool(schoolId, school) {
    const requestOptions = {
        method: 'PUT',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/update-school?schoolId=${schoolId}&school=${school}`, requestOptions)
        .then(handleResponse)
        .then(school => {
            return school;
        });
}

function newTeacher(schoolId, name, surname, email) {
    const requestOptions = {
        method: 'POST',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/new-teacher?schoolId=${schoolId}&name=${name}&surname=${surname}&email=${email}`, requestOptions)
        .then(handleResponse)
        .then(teacher => {
            return teacher;
        });
}

function deleteTeacher(teacherId) {
    const requestOptions = {
        method: 'DELETE',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/delete-teacher?teacherId=${teacherId}`, requestOptions)
        .then(handleResponse)
        .then(school => {
            return school;
        });
}

function updateTeacher(teacherId, name, surname, email) {
    const requestOptions = {
        method: 'PUT',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/schools/update-teacher?teacherId=${teacherId}&name=${name}&surname=${surname}&email=${email}`, requestOptions)
        .then(handleResponse)
        .then(teacher => {
            return teacher;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}