import React from 'react';
import ReactPlayer from 'react-player';
const VIDEO_URL = process.env.REACT_APP_VIDEO_URL

function Video({ item }) {
    return (
        <>
                <h5>{item.title}</h5>
                <p>{item.text}</p>
                {item.filename &&
                    <ReactPlayer url={`${VIDEO_URL}${item.filename}`} controls={true} height="auto" className='box_rosa list_media'/>
                }
        </>
    );
}

export { Video };
