import './MenuInterface.css';
import React, { useEffect } from 'react';
import { menuActions } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Shared/Header';
import { Link } from 'react-router-dom';
  
function SubMenuInterface(props) {
    const submenu = useSelector(state => state.submenu);
    const { mainMenuId, subMenuId } = props.match.params;
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(menuActions.getStudentSubMenu(user.studentId, user.classId, mainMenuId, subMenuId));
    }, [user.studentId, user.classId, dispatch, mainMenuId, subMenuId]);
    return (
      <div className="student-submenu-main">
        <div className="student-dash-main">
          <Header />
          <div className="student-dash-inner">
            <div className="stuback-arrow"><Link to={"/student/menu/"}><img src="/back-arrow.png" className='img-fluid' alt="back"/></Link></div>
            {submenu.loading && 
              <>
                <em>Loading submenu...</em>
              </>
            }
            {submenu.items && submenu.items.section &&
              <>
                <div className={`student-submenu-cont ${submenu.items.section.toLowerCase().replace(' ', '_')}`}>
                  <div className="stumenu-icon">
                    <img src={`/images/section_${mainMenuId}.svg`} alt={(submenu.items.section) ? submenu.items.section : ''}/>
                  </div>
                  <div className="stusubmenu-cont">
                    <h4>{(submenu.items.section) ? submenu.items.section : ''} </h4>
                    <div className="stusubmenu-controw">
                      <div className="stusubmenu-contcol">
                        <ul>
                          {submenu.items.secondLevelMenu.thirdLevelMenu &&
                            <>
                              {submenu.items.secondLevelMenu.thirdLevelMenu.map((thirdLevelItem, ind) =>
                                <li key={ind}>
                                  {(thirdLevelItem.is_enable !== true) && 
                                    <Link to={`/student/menu`} className='disabled'>{thirdLevelItem.section}</Link>
                                  }
                                  {(thirdLevelItem.is_enable === true) && 
                                    <Link to={`/student/course-questions/${thirdLevelItem.sectionId}`}>{thirdLevelItem.section}</Link>
                                  }
                                </li>
                              )}
                            </>
                          }
                        </ul>
                      </div>
                    </div>                
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    );
}
export default SubMenuInterface;