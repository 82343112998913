import { schoolConstants } from '../_constants';
import { schoolService } from '../_services';
import { showAlert } from '.';
import { history } from '../_helpers';

export const schoolActions = {
    getSchools,
    getSchoolDetail,
    newSchool,
    updateSchool,
    deleteSchool,
    newTeacher,
    updateTeacher,
    deleteTeacher
};

function getSchools() {
    return dispatch => {
        dispatch(request());

        schoolService.getSchools()
            .then(
                schools => { 
                    dispatch(success(schools));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(schools) { return { type: schoolConstants.SCHOOL_REQUEST, schools } }
    function success(schools) { return { type: schoolConstants.SCHOOL_SUCCESS, schools } }
    function failure(error) { return { type: schoolConstants.SCHOOL_FAILURE, error } }
}

function getSchoolDetail(schoolId) {
    return dispatch => {
        dispatch(request());

        schoolService.getSchoolDetail(schoolId)
            .then(
                school => { 
                    dispatch(success(school));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(school) { return { type: schoolConstants.SCHOOL_REQUEST, school } }
    function success(school) { return { type: schoolConstants.SCHOOL_SUCCESS, school } }
    function failure(error) { return { type: schoolConstants.SCHOOL_FAILURE, error } }
}

function newSchool(school) {
    return dispatch => {
        dispatch(request());

        schoolService.newSchool(school)
            .then(
                school => { 
                    dispatch(success(school));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(school) { return { type: schoolConstants.SCHOOL_DETAIL_REQUEST, school } }
    function success(school) { return { type: schoolConstants.SCHOOL_DETAIL_SUCCESS, school } }
    function failure(error) { return { type: schoolConstants.SCHOOL_DETAIL_FAILURE, error } }
}

function updateSchool(schoolId, school) {
    return dispatch => {
        dispatch(request());

        schoolService.updateSchool(schoolId, school)
            .then(
                school => { 
                    dispatch(success(school));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(school) { return { type: schoolConstants.SCHOOL_DETAIL_REQUEST, school } }
    function success(school) { return { type: schoolConstants.SCHOOL_DETAIL_SUCCESS, school } }
    function failure(error) { return { type: schoolConstants.SCHOOL_DETAIL_FAILURE, error } }
}

function deleteSchool(schoolId) {
    return dispatch => {
        dispatch(request());

        schoolService.deleteSchool(schoolId)
            .then(
                school => { 
                    console.log("DE:ETE SCHOOL ", school)
                    schoolService.getSchools()
                         .then(
                             schools => { 
                                 dispatch(success(schools));
                             },
                             error => {
                                 dispatch(failure(error.toString()));
                                 dispatch(showAlert({
                                     type: 'danger',
                                     title: 'Oops!',
                                     content: error.toString(),
                                     showCancel: false,
                                 }));
                             }
                         );
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(school) { return { type: schoolConstants.SCHOOL_DETAIL_REQUEST, school } }
    function success(schools) { return { type: schoolConstants.SCHOOL_SUCCESS, schools } }
    function failure(error) { return { type: schoolConstants.SCHOOL_DETAIL_FAILURE, error } }
}

function newTeacher(schoolId, name, surname, email) {
    return dispatch => {
        dispatch(request());

        schoolService.newTeacher(schoolId, name, surname, email)
            .then(
                teacher => { 
                    schoolService.getSchools()
                        .then(
                            schools => { 
                                dispatch(success(schools));
                            },
                            error => {
                                dispatch(failure(error.toString()));
                                dispatch(showAlert({
                                    type: 'danger',
                                    title: 'Oops!',
                                    content: error.toString(),
                                    showCancel: false,
                                }));
                            }
                        );
                    // dispatch(success(teacher));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(teacher) { return { type: schoolConstants.SCHOOL_DETAIL_REQUEST, teacher } }
    function success(schools) { return { type: schoolConstants.SCHOOL_SUCCESS, schools } }
    function failure(error) { return { type: schoolConstants.SCHOOL_DETAIL_FAILURE, error } }
}

function updateTeacher(teacherId, name, surname, email) {
    return dispatch => {
        dispatch(request());

        schoolService.updateTeacher(teacherId, name, surname, email)
            .then(
                school => { 
                    schoolService.getSchools()
                        .then(
                            schools => { 
                                // console.log("NEW TEACHER prendo le scuole ", schools)
                                dispatch(success(schools));
                            },
                            error => {
                                dispatch(failure(error.toString()));
                                dispatch(showAlert({
                                    type: 'danger',
                                    title: 'Oops!',
                                    content: error.toString(),
                                    showCancel: false,
                                }));
                            }
                        );
                    // dispatch(success(teacher));
                    dispatch(success(school));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(school) { return { type: schoolConstants.SCHOOL_DETAIL_REQUEST, school } }
    function success(schools) { return { type: schoolConstants.SCHOOL_SUCCESS, schools } }
    function failure(error) { return { type: schoolConstants.SCHOOL_DETAIL_FAILURE, error } }
}

function deleteTeacher(teacherId) {
    return dispatch => {
        dispatch(request());

        schoolService.deleteTeacher(teacherId)
            .then(
                school => { 
                    schoolService.getSchools()
                        .then(
                            schools => { 
                                dispatch(success(schools));
                            },
                            error => {
                                dispatch(failure(error.toString()));
                                dispatch(showAlert({
                                    type: 'danger',
                                    title: 'Oops!',
                                    content: error.toString(),
                                    showCancel: false,
                                }));
                            }
                        );
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(school) { return { type: schoolConstants.SCHOOL_DETAIL_REQUEST, school } }
    function success(schools) { return { type: schoolConstants.SCHOOL_SUCCESS, schools } }
    function failure(error) { return { type: schoolConstants.SCHOOL_DETAIL_FAILURE, error } }
}


function getSectionHeader(sectionId) {
    return dispatch => {
        dispatch(request());

        schoolService.getSectionHeader(sectionId)
            .then(
                sectionHeader => { 
                    dispatch(success(sectionHeader));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(sectionHeader) { return { type: schoolConstants.SECTION_HEADER_REQUEST, sectionHeader } }
    function success(sectionHeader) { return { type: schoolConstants.SECTION_HEADER_SUCCESS, sectionHeader } }
    function failure(error) { return { type: schoolConstants.SECTION_HEADER_FAILURE, error } }
}

function getSchoolHeader(schoolId) {
    return dispatch => {
        dispatch(request());

        schoolService.getSchoolHeader(schoolId)
            .then(
                schoolHeader => { 
                    dispatch(success(schoolHeader));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(schoolHeader) { return { type: schoolConstants.CLASS_HEADER_REQUEST, schoolHeader } }
    function success(schoolHeader) { return { type: schoolConstants.CLASS_HEADER_SUCCESS, schoolHeader } }
    function failure(error) { return { type: schoolConstants.CLASS_HEADER_FAILURE, error } }
}
