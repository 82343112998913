import React from 'react';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

function TextItem({ item }) {
    return (
        <>
            <h5 dangerouslySetInnerHTML={{__html: item.title}}></h5>
            <p dangerouslySetInnerHTML={{__html: item.text}}></p>
            {item.filename &&
                <p className='list_media'><img _style={{width:'600px'}} src={`${IMAGE_URL}${item.filename}`} alt={item.title} /></p>
            }
            <hr class="rosa"></hr>
        </>
    );
}

export { TextItem };