import logo from '../_assets/img/logo-white.png';
import unifi from '../_assets/img/unifi.png';
import crfirenze from '../_assets/img/crfirenze.png';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import React from 'react';
import './HomePage.css';

function HomePage() {
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const userLoggedIn = useSelector(state => state.userAuth.loggedIn);
  const user = useSelector(state => state.userAuth.user);
  return (
    <div className="home-main">
      <div className="home-inner">
        <div className="home-left">
          <div className="home-left-logo">
            <img src={logo} alt="logo" className='img-fluid'/>
          <p style={{"text-align": "justify", "color": "white"}}><br/><br/>
            VITO è una web-app che propone una valutazione ed un potenziamento delle competenze digitali di studenti di scuola secondaria di primo e secondo grado. Nello specifico, agisce sui processi di valutazione della fonte e di integrazione intertestuale. 
            VITO è stato sviluppato grazie al co-finanziamento erogato dalla Fondazione CR di Firenze e dalla Banca d’Italia.
          </p>
          </div>
        </div>
        <div className="home-right">
          <div className="home-right-inner">
            {loggedIn && 
              <Link to="/student/menu">STUDENTE</Link>
            }
            {!loggedIn && 
              <Link to="/student/login">STUDENTE</Link>
            }
            {!userLoggedIn && 
              <>
                <Link to="/teacher/login">INSEGNANTE</Link>
                <Link to="/researcher/login">RICERCATORE</Link>
              </>
            }
            {userLoggedIn && 
              <>
                {user && user.role.role === 'Teacher' && 
                  <Link to="/teacher/classes-page">INSEGNANTE</Link>
                }
                {user && user.role.role !== 'Teacher' && 
                  <Link to="/teacher/login">INSEGNANTE</Link>
                }
                {user && user.role.role === 'Researcher' && 
                  <Link to="/researcher/schools">RICERCATORE</Link>
                }
                {user && user.role.role !== 'Researcher' && 
                  <Link to="/researcher/login">RICERCATORE</Link>
                }
              </>
            }
            <p class={"mt-5 mb-3 text-muted"}>&copy; Mathema srl -2023</p>
            <div className="loghi">
                <img src={unifi} alt="unifi" className='img-fluid'/>
                <img src={crfirenze} alt="Fondazione Cassa di Risparmio di Firenze" className='img-fluid'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
