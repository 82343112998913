export const schoolConstants = {
    SCHOOL_REQUEST: 'SCHOOL_REQUEST',
    SCHOOL_SUCCESS: 'SCHOOL_SUCCESS',
    SCHOOL_FAILURE: 'SCHOOL_FAILURE',

    SCHOOL_DETAIL_REQUEST: 'SCHOOL_DETAIL_REQUEST',
    SCHOOL_DETAIL_SUCCESS: 'SCHOOL_DETAIL_SUCCESS',
    SCHOOL_DETAIL_FAILURE: 'SCHOOL_DETAIL_FAILURE',

    SECTION_HEADER_REQUEST: 'SECTION_HEADER_REQUEST',
    SECTION_HEADER_SUCCESS: 'SECTION_HEADER_SUCCESS',
    SECTION_HEADER_FAILURE: 'SECTION_HEADER_FAILURE',

    SCHOOL_HEADER_REQUEST: 'SCHOOL_HEADER_REQUEST',
    SCHOOL_HEADER_SUCCESS: 'SCHOOL_HEADER_SUCCESS',
    SCHOOL_HEADER_FAILURE: 'SCHOOL_HEADER_FAILURE',
};