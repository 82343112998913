export const classConstants = {
    CLASS_REQUEST: 'CLASS_REQUEST',
    CLASS_SUCCESS: 'CLASS_SUCCESS',
    CLASS_FAILURE: 'CLASS_FAILURE',

    CLASS_DETAIL_REQUEST: 'CLASS_DETAIL_REQUEST',
    CLASS_DETAIL_SUCCESS: 'CLASS_DETAIL_SUCCESS',
    CLASS_DETAIL_FAILURE: 'CLASS_DETAIL_FAILURE',

    STUDENTS_RESULT_REQUEST: 'STUDENTS_RESULT_REQUEST',
    STUDENTS_RESULT_SUCCESS: 'STUDENTS_RESULT_SUCCESS',
    STUDENTS_RESULT_FAILURE: 'STUDENTS_RESULT_FAILURE',

    UPDATE_CALENDAR_REQUEST: 'UPDATE_CALENDAR_REQUEST',
    UPDATE_CALENDAR_SUCCESS: 'UPDATE_CALENDAR_SUCCESS',
    UPDATE_CALENDAR_FAILURE: 'UPDATE_CALENDAR_FAILURE',

    SECTION_STUDENTS_REQUEST: 'SECTION_STUDENTS_REQUEST',
    SECTION_STUDENTS_SUCCESS: 'SECTION_STUDENTS_SUCCESS',
    SECTION_STUDENTS_FAILURE: 'SECTION_STUDENTS_FAILURE',

    SECTION_HEADER_REQUEST: 'SECTION_HEADER_REQUEST',
    SECTION_HEADER_SUCCESS: 'SECTION_HEADER_SUCCESS',
    SECTION_HEADER_FAILURE: 'SECTION_HEADER_FAILURE',

    CLASS_HEADER_REQUEST: 'CLASS_HEADER_REQUEST',
    CLASS_HEADER_SUCCESS: 'CLASS_HEADER_SUCCESS',
    CLASS_HEADER_FAILURE: 'CLASS_HEADER_FAILURE',

    SECTION_DATA_REQUEST: 'SECTION_DATA_REQUEST',
    SECTION_DATA_SUCCESS: 'SECTION_DATA_SUCCESS',
    SECTION_DATA_FAILURE: 'SECTION_DATA_FAILURE',

    FEEDBACK_REQUEST: 'FEEDBACK_REQUEST',
    FEEDBACK_SUCCESS: 'FEEDBACK_SUCCESS',
    FEEDBACK_FAILURE: 'FEEDBACK_FAILURE',
};
