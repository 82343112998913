import { userAuthHeader } from '../_helpers';
import { userService } from './';
const API_URL = process.env.REACT_APP_API_URL
export const classService = {
    getClasses,
    newClass,
    updateClass,
    deleteClass,
    getClasseDetail,
    updateCalendar,
    getStudentsBySection,
    getSectionHeader,
    getClassHeader,
    getSectionDataById,
    postFeedback,
    getStudentsResultData
};

function getClasses(schoolId) {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/classes/get-classes?schoolId=${schoolId}`, requestOptions)
        .then(handleResponse)
        .then(classes => {
            return classes;
        });
}
function newClass(schoolId, teacherId, name, studentNum) {
    const requestOptions = {
        method: 'POST',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/classes/new-class?schoolId=${schoolId}&teacherId=${teacherId}&name=${name}&studentNum=${studentNum}`, requestOptions)
        .then(handleResponse)
        .then(myClass => {
            return myClass;
        });
}
function updateClass(classId, teacherId, name, studentNum) {
    const requestOptions = {
        method: 'PUT',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/classes/update-class?classId=${classId}&teacherId=${teacherId}&name=${name}&studentNum=${studentNum}`, requestOptions)
        .then(handleResponse)
        .then(myClass => {
            return myClass;
        });
}
function deleteClass(classId) {
    const requestOptions = {
        method: 'DELETE',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/classes/delete-class?classId=${classId}`, requestOptions)
        .then(handleResponse)
        .then(myClass => {
            return myClass;
        });
}

function getClasseDetail(classId, teacherId) {
    if(!teacherId)
    {
        teacherId = 0;
    }
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/classes/get-class-by-id?classId=${classId}&teacherId=${teacherId}`, requestOptions)
        .then(handleResponse)
        .then(classes => {
            return classes;
        });
}

function getStudentsResultData(classId) {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/classes/get-students-result-data?classId=${classId}`, requestOptions)
        .then(handleResponse)
        .then(classes => {
            return classes;
        });
}

function getStudentsBySection(sectionId, classId) {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/teachers/students/section-details?sectionId=${sectionId}&classId=${classId}`, requestOptions)
        .then(handleResponse)
        .then(students => {
            return students;
        });
}

function getSectionDataById(sectionId, studentId) {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/teachers/get-student-course-header?sectionId=${sectionId}&studentId=${studentId}`, requestOptions)
        .then(handleResponse)
        .then(section => {
            return section;
        });
}

function getSectionHeader(sectionId) {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/teachers/get-class-section-header?sectionId=${sectionId}`, requestOptions)
        .then(handleResponse)
        .then(sectionHeader => {
            return sectionHeader;
        });
}

function getClassHeader(classId) {
    const requestOptions = {
        method: 'GET',
        headers: userAuthHeader()
    };
    return fetch(`${API_URL}/teachers/get-class-school?classId=${classId}`, requestOptions)
        .then(handleResponse)
        .then(classHeader => {
            return classHeader;
        });
}

function updateCalendar(classId, scheduledOpening) {
    const requestOptions = {
        method: 'POST',
        headers: userAuthHeader(),
        body: JSON.stringify({ scheduledOpening: scheduledOpening, classId: classId })
    };
    return fetch(`${API_URL}/teachers/update-calendar`, requestOptions)
        .then(handleResponse)
        .then(calendar => {
            return calendar;
        });
}

function postFeedback(answers) {
    const requestOptions = {
        method: 'POST',
        headers: userAuthHeader(),
        body: JSON.stringify(answers)
    };

    return fetch(`${API_URL}/teachers/post-student-feedback`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
