import logo from '../../../_assets/img/logo-white.png';
import unifi from '../../../_assets/img/unifi.png';
import mathema from '../../../_assets/img/mathema.png';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from "formik";
import './LoginPage.css';
import { studentActions, userActions, alertActions } from '../../../_actions';
import { Alert } from 'react-bootstrap';
import { history } from '../../../_helpers';


function LoginPage() {
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useSelector(state => state.alert);

  // reset login status
  useEffect(() => { 
    dispatch(studentActions.logout());
    dispatch(userActions.logout());
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  });

  function handleSubmit(values) {
      if (values.code) {
          // get return url from location state or default to home page
          const { from } = location.state || { from: { pathname: "/student/menu" } };
          dispatch(studentActions.login(values.code.trim(), from));
      }
  }
  return (
    <div className="home-main">
      <div className="home-inner">
        <div className="home-left">
          <div className="home-left-logo">
            <img src={logo} alt="logo" className='img-fluid'/>
          </div>
          <div className="home-left-adlogo">
            <div className="hmlft-adlogo-col">
                <img src={unifi} alt="unifi" className='img-fluid'/>
            </div>
            <div className="hmlft-adlogo-col">
                <img src={mathema} alt="mathema" className='img-fluid'/>
            </div>
          </div>
        </div>
        <div className="home-right">
          <div className="home-right-inner">
            {alert.message &&
              <Alert variant='danger'>
                {alert.message}
              </Alert>
            }
            <h1 className="pg-ttl">Area Studente</h1>
            <p>Inserisci il tuo codice</p>
            <div className="login-frmmain">
              <Formik initialValues={{ code: '' }} onSubmit={handleSubmit}>
                <Form>
                  <div className="form-group">
                    <Field type="text" required={[true, 'Code is required']} maxLength={[190, 'The name can be no longer than 190 characters long']} name="code" className="form-control" />
                  </div>
                  <div className="submit-btn">
                    <button type="submit">
                      {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                      Accedi
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
