import React from 'react';
import { CourceMainContent } from './';
import { useSelector } from 'react-redux';

function WorkSheet({ worksheet, isEditableForm, values, studentSections, setFieldValue, buttons, loading, showConvergence, showDisableConvergence, showFeedback}) {
    const user = useSelector(state => state.userAuth.user);
    var righe = [];
    for (var i = 0; i < worksheet.prospective.length; i++) {
        righe.push([worksheet.prospective[i],worksheet.in_favour[i],worksheet.opposite[i],worksheet.sintesi[i]]);
    }

    return (
        <>
            <div className="student-course-row2">
                <div className="stdtcrs-worksheet">
                    <div className="stdtcrs-worksheet-row2">
                        <div className="stdtcrs-wrkstrw2-hdr">
                            {worksheet.prospective && 
                                <div className="stdtcrs-wrkstrw2-hdr-left col4">
                                    <h6>PROSPETTIVA</h6>
                                </div>
                            }
                            {worksheet.in_favour && 
                                <div className="stdtcrs-wrkstrw2-hdr-right col4">
                                    <h6>ARGOMENTAZIONE A FAVORE</h6>
                                </div>
                            }
                            {worksheet.opposite && 
                                <div className="stdtcrs-wrkstrw2-hdr-left col4">
                                    <h6>ARGOMENTAZIONE CONTRO</h6>
                                </div>
                            }
                            {worksheet.sintesi && 
                                <div className="stdtcrs-wrkstrw2-hdr-right col4">
                                    <h6>SINTESI</h6>
                                </div>
                            }
                        </div>
                        {righe.map((items, index1) => 
                            <div className="stdtcrs-wrkstrw2-cont riga">
                                {items.map((item, index) => 
                                    <div className="stdtcrs-wrkstrw2-cont-left col4 COLONNA">
                                        <div className="worksheet_textarea" key={index}>
                                            <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={isEditableForm} studentSections={studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence} values={values} showFeedback={showFeedback}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {buttons && 
                <>
                    {buttons.map((button, index) =>
                    <div key={`btn_${index}`}> 
                        {(isEditableForm || showConvergence(true)) && (!user || (user.role.role !== "Teacher" && user.role.role !== "Researcher")) && !showFeedback && button.type === 'buttonConfirm' && 
                        <div className="student-course-row3" style={{textAlign: "right"}}>
                            <button type="submit" disabled={(loading) ? true : false}>{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                        </div>
                        }
                    </div>
                    )}
                </>
            }
        </>
    );
}

export { WorkSheet };