import logo from '../../../_assets/img/logo-white.png';
import unifi from '../../../_assets/img/unifi.png';
import mathema from '../../../_assets/img/mathema.png';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from "formik";
import './LoginPage.css';
import { userActions, studentActions, alertActions } from '../../../_actions';
import { Alert } from 'react-bootstrap';
import { history } from '../../../_helpers';


function LoginPage() {
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useSelector(state => state.alert);

  // reset login status
  useEffect(() => { 
    dispatch(userActions.logout());
    dispatch(studentActions.logout());
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, [dispatch]);

  function handleSubmit(values, actions) {
      if (values.email && values.password) {
          // get return url from location state or default to home page
          // const { from } = location.state || { from: { pathname: "/researcher/classes-page" } };
          const { from } = location.state || { from: { pathname: "/researcher/schools" } };
          dispatch(userActions.login(values.email.trim(), values.password.trim(), 'Researcher', from));
      }
  }
  return (
    <div className="home-main">
      <div className="home-inner">
        <div className="home-left">
          <div className="home-left-logo">
            <img src={logo} alt="logo" className='img-fluid'/>
          </div>
          <div className="home-left-adlogo">
            <div className="hmlft-adlogo-col">
                <img src={unifi} className='img-fluid' alt="logo" />
            </div>
            <div className="hmlft-adlogo-col">
                <img src={mathema} className='img-fluid' alt="logo" />
            </div>
          </div>
        </div>
        <div className="home-right">
          <div className="home-right-inner">
            {alert.message &&
              <Alert variant='danger'>
                {alert.message}
              </Alert>
            }
            <h1 className="pg-ttl">RESEARCHER AREA</h1>
            <div className="login-frmmain">            
              <Formik initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
                <Form>
                  <div className="form-group">
                    <label className="form-label">E_MAIL</label>
                    <Field type="text" required={[true, 'E-mail is required']} maxLength={[190, 'The email must be no longer than 190 characters long']} name="email" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label className="form-label">PASSWORD</label>
                    <Field type="password" required={[true, 'Password is required']} name="password" className="form-control" />
                  </div>
                  <div className="submit-btn">
                    <button type="submit">
                      {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                      Login
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
