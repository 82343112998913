import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { userAuth } from './userAuth.reducer';
import { alert } from './alert.reducer';
import { menu } from './menu.reducer';
import { submenu } from './submenu.reducer';
import { section } from './section.reducer';
import { answer } from './answer.reducer';
import { schools } from './school.reducer';
import { classes, classDetail, updateCalendar, students, sectionHeader, classHeader, sectionData, feedback, studentsResult } from './class.reducer';
import alerts from './alerts.reducer';

const rootReducer = combineReducers({
    authentication,
    alert,
    menu,
    submenu,
    section,
    answer,
    alerts,
    userAuth,
    classes,
    schools,
    classDetail,
    updateCalendar, 
    students,
    sectionHeader,
    classHeader,
    sectionData,
    feedback,
    studentsResult
});

export default rootReducer;