import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { classActions, schoolActions } from '../../../_actions';
import { Formik, Form, Field } from "formik";
import Select, { Option, ReactSelectProps } from 'react-select'
import Header from '../Shared/Header';
import { Row, Col, Accordion, Card, Button, Table } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import Moment from 'moment';
import './ClassesPage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
  
class ClassesPage extends Component {
  csvLink = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      classData: null,
    };
  }

  componentWillMount() {
    const schoolId = this.props.match.params.schoolId;
    this.props.getClasses(schoolId);
  }

  SetUpdateClass(classData) {
    this.setState({teacherId: classData.teacherId});
    this.setState({name: classData.name});
    this.setState({classId: classData.classId});
    this.setState({studentNum: classData.students.length});
    this.setState({showNew: false}); 
    this.setState({showUpdate: true});

    let free_students = 0;
    let nofree_students = 0;
    classData.students.map(s => {
      if(!s.user.last_login) free_students++
      if(s.user.last_login) nofree_students++
    })
    this.setState({free_students: free_students})
    this.setState({nofree_students: nofree_students})
  }

  studentResults() {
  
    // let data = [
    //   ['Class Name', 'Student Code', 'Section Name(First Level)', 'Section Name(Second Level)', 'Section Name(Third Level)', 'Start Time', 'End Time', 'Count Click', 'Item Title', 'Answer', 'Score', 'Reverse']
    // ];
    let data = [];
    let TITLES = {};
    let colTitles = ['Class Name', 'Student Code'];
    if(this.state.classData)
    {
      var ANSWERS = {};
      for (let index = 0; index < this.state.classData.students.length; index++) {
        const element = this.state.classData.students[index];
        for (let i = 0; i < element.StudentSections.length; i++) {
          const studentSection = element.StudentSections[i];
          for (let j = 0; j < studentSection.studentAnswers.length; j++) {
            const studentAnswer = studentSection.studentAnswers[j];
            let answer = '';
            if(studentAnswer.item && studentAnswer.item.typeItem && studentAnswer.item.typeItem.typeItem === "closed answer question")
            {
              answer = studentAnswer.answer.answer;
            } else if(studentAnswer.item && (studentAnswer.item.typeItemId == 9 ||studentAnswer.item.typeItemId == 8 || studentAnswer.item.typeItemId == 7 || studentAnswer.item.typeItemId == 6 || studentAnswer.item.typeItemId == 3))
            {
              answer = studentAnswer.value;
            } else {
              answer = studentAnswer.text;
              if(answer) {
                answer = answer.replace(/&lt;br&gt;/g," ");;
                answer = answer.replace(/\"/g,"'");;
                answer = answer.replace(/(\r\n|\n|\r)/gm, "");
              }
            }
            let clickCount = 0;
            let startTime = '';
            let endTime = '';
            if(studentSection.log)
            {
              let log = JSON.parse(studentSection.log);
              startTime = Moment(log.startTime).format('DD/MM/YYYY HH:mm:ss');
              endTime = Moment(log.endTime).format('DD/MM/YYYY HH:mm:ss');
              if(log.job)
              {
                clickCount = log.job.length;
              }
            }
            
            if(!studentAnswer.item) continue;
            let itemId = studentAnswer.item.itemId;
            let tit = studentAnswer.item.title;
            let title = tit;
            if(title) title = title.replace(/&lt;br&gt;/g," ");;
            if(title) title = title.replace(/\"/g,"'");;
            if(title) title = title.replace(/[\r\n]/gm, " ");
            if(title) title = title.replace(/\s+/gm, " ");
            if(! title ) {
              title = studentSection.section.section;
              if(itemId == 290 || itemId == 291 || itemId == 556 || itemId == 557) title += " Prospective";
              if(itemId == 292 || itemId == 293 || itemId == 558 || itemId == 559) title += " In favour";
              if(itemId == 294 || itemId == 296 || itemId == 560 || itemId == 561) title += " Opposite";
              if(itemId == 553 || itemId == 554 || itemId == 562 || itemId == 563) title += " Sintesi";
            }
            var sectionFirstLevelSorting = studentSection.section.parentLevelSection.parentLevelSection.sorting;
            var sectionSecondLevelSorting = studentSection.section.parentLevelSection.sorting;
            var sectionThirdLevelSorting = studentSection.section.sorting;
            var sectionSorting = sectionFirstLevelSorting + "" + sectionSecondLevelSorting + "" + sectionThirdLevelSorting;
            TITLES[itemId] = {'title': title, 'sorting': sectionSorting, itemId: itemId};
            //data.push([this.state.classData.name, element.code, studentSection.section.parentLevelSection.parentLevelSection.section, studentSection.section.parentLevelSection.section, studentSection.section.section, startTime, endTime, clickCount, title, answer, studentAnswer.score, (studentAnswer.item && studentAnswer.item.reverse) ? studentAnswer.item.reverse : '']);
            if(!ANSWERS[element.code]) ANSWERS[element.code] = {};
            var section = studentSection.section.parentLevelSection.parentLevelSection.section + "-" + studentSection.section.parentLevelSection.section + "-" + studentSection.section.section;
            // ANSWERS[element.code].push(section, startTime, endTime, clickCount, answer, studentAnswer.score, (studentAnswer.item && studentAnswer.item.reverse) ? studentAnswer.item.reverse : '')
            // ANSWERS[element.code][itemId].push(section, answer, studentAnswer.score, (studentAnswer.item && studentAnswer.item.reverse) ? studentAnswer.item.reverse : '');
            var score = studentAnswer.score || ' ';
            if(!answer) answer = ' ';
            var reverse = studentAnswer.item.reverse ? studentAnswer.item.reverse : ' ';
            ANSWERS[element.code][itemId] = {answer: answer, score: score, reverse: reverse};
          }
        }
      }

      var keys = Object.keys(TITLES);
      var LIST_TITLES = keys.map(function(v) { return TITLES[v]; });
      var NEW_LIST = LIST_TITLES.sort((a, b) => a.sorting - b.sorting)

      let STUDENT = {};
      for(var i in NEW_LIST) {
        var itemId = NEW_LIST[i].itemId;
        var title = NEW_LIST[i].title;
        // colTitles.push('Section', TITLES[itemId], 'Score', 'Reverse');
        colTitles.push(title, 'Score', 'Reverse');
        for(var code in ANSWERS) {
          if(!STUDENT[code]) STUDENT[code] = [this.state.classData.name, code];
          var answers = "";
          var score = "";
          var reverse = "";
          if(ANSWERS[code][itemId]) {
            answers = ANSWERS[code][itemId].answer;
            score = ANSWERS[code][itemId].answer.score;
            reverse = ANSWERS[code][itemId].answer.reverse;
          }
          STUDENT[code].push(answers);
          STUDENT[code].push(score);
          STUDENT[code].push(reverse);
        }
      }
      data.push(colTitles);
      for(var code in STUDENT) {
        data.push(STUDENT[code]);
      }
    }
    return data;
  }

  SetNewClass () {
    this.setState({showNew: true}); 
    this.setState({showUpdate: false});
    this.setState({teacherId: this.props.classes.items.teachers[0].teacherId});
  }

  handleSubmit = async () => {
    await CallApi(this.props.newClass, [this.props.match.params.schoolId, this.state.teacherId, this.state.name, this.state.studentNum]);
    this.setState({teacherId: ''});
    this.setState({name: ''});
    this.setState({studentNum: ''});
    this.setState({showNew: false});
    this.props.getClasses(this.props.match.params.schoolId);
  }

  handleUpdate = async () => {
    await CallApi(this.props.updateClass, [this.state.classId, this.state.teacherId, this.state.name, this.state.studentNum]);
    this.setState({classId: ''});
    this.setState({teacherId: ''});
    this.setState({studentNum: ''});
    this.setState({name: ''});
    this.setState({showUpdate: false});
    this.props.getClasses(this.props.match.params.schoolId);
  }

  handleDelete = async (classData) => {
    await CallApi(this.props.deleteClass, [classData.classId]);
    this.setState({classId: ''});
    this.setState({teacherId: ''});
    this.setState({name: ''});
    this.props.getClasses(this.props.match.params.schoolId);
  }
  discardForm() {
    this.setState({showNew: false})
    this.setState({showUpdate: false})
    this.setState({name: ''})
    this.setState({teacherId: ''})
  }

  checkNumber (v) {
    if(v < this.state.studentNum) {
      let diff = parseInt(this.state.studentNum) - parseInt(v)
      if(diff > this.state.free_students) {
        // alert("Indicare un numero di studenti maggiore o uguale a " + this.state.nofree_students )
        this.setState({studentNum: this.state.studentNum});
        return
      }
    }
    this.setState({studentNum: v});
  }

  render() {
    const classes = this.props.classes;

    let school;
    let teachers = [];
    if(classes && classes.items) school = this.props.classes.items.school
    if(classes && classes.items) teachers = this.props.classes.items.teachers
    return (
      <div className="teacher-dash-main">
        <Header />
        <div className="teacher-dash-inner">
          <div className="teacher-classes-main">
            <h1 className="pg-ttl" style={{textAlign: "center"}}>CLASSI </h1>
            <a href={"/researcher/schools"}><img src="/back-arrow.png" alt="back-arrow" /></a>
            {school && <h3 __className="pg-ttl" style={{textAlign: "center"}}>{school.school}</h3>}
            {this.state.showNew ? (
              <div style={{"height":"260px"}}>
                <Formik >
                  <Form>
                  <div className="form-group">
                    <label className="form-label"><b>Nome della classe</b></label>
                    <Field type="text" required={[true, 'Inserisci il nome della classe']} name="name" className="form-control" defaultValue={this.state.name} onChange={(event) => {this.setState({name: event.target.value});}}/>
                    <label className="form-label"><b>Insegnante</b></label><br/>
                    <select name="teacherId" onChange={(event) => {this.setState({teacherId: event.target.value});}}>
                        {teachers.map((obj) => (
                        <option value={obj.teacherId}>{obj.surname} {obj.name}</option>
                        ))}
                    </select><br></br>
                    <label className="form-label"><b>Numero di studenti</b></label>
                    <Field type="number" required={[true, 'Inserisci il nome della classe']} name="studentNum" className="form-control" defaultValue={this.state.studentNum} onChange={(event) => {this.setState({studentNum: event.target.value});}}/>
                    <button className="btn btn-reset d-inline-block" style={{marginRight: '3px', lineHeight: '2em', marginTop: '5px' }} onClick={this.discardForm.bind(this)}>Annulla</button>
                    <button className="btn btn-default d-inline-block" style={{lineHeight: '2em', marginTop: '5px' }} onClick={this.handleSubmit.bind(this)}>{this.props.loading && <span className="spinner-border spinner-border-sm mr-1"></span>}Salva</button>
                  </div>
                  </Form>
                </Formik>
              </div>
            ) : (
              <div>
                <button className="btn-default" style={{lineHeight: '2em'}} onClick={(event) => { this.SetNewClass();}}>Inserisci nuova classe</button><br/>
            <br/>
              </div>
            )
            }
            <Accordion defaultActiveKey="0">
              {classes.items && classes.items.classes && 
                <>
                    {classes.items.classes.map((classData, index) =>
                      <Card className="acc-cls-main" key={index}>
                        <Card.Header>
                          <Row>
                            <Col>
                              <Accordion.Toggle as={Button} separator={";"} filename={"student-result.csv"} variant="link" eventKey={`${index}`}> {classData.name} - <span className="teacherName">{(classData.teacher) ? classData.teacher.name : ""} {(classData.teacher) ? classData.teacher.surname: ""}</span></Accordion.Toggle>
                            </Col>
                            <Col>
                              <ul className="dcvc-btn">
                                <li style={{fontFamily: 'Fira Code', fontSize: '12px', cursor: 'pointer'}} onClick={(event) => {this.SetUpdateClass(classData);}}><img src="/images/edit-icon.png" alt="view" className='img-fluid'/> Modifica</li>
                                <li style={{fontFamily: 'Fira Code', fontSize: '12px', cursor: 'pointer'}} onClick={(event) => {this.handleDelete(classData);}}><img src="/images/delete-icon.png" alt="view" className='img-fluid'/> Cancella</li>
                                <li></li>
                                <li>
                                  <span className='link' 
                                    onClick={(event) => {
                                      this.props.getStudentsResultData(classData.classId, 
                                        () => {
                                          this.setState({classData: this.props.studentsResult.items.classes});
                                          this.csvLink.current.link.click();
                                        });
                                    }}><img src="/Download-icon.png" alt="Download" className='img-fluid'/> Scarica i risultatati</span>
                                </li>
                                {classData.teacher && 
                                  <li><Link to={`/researcher/classes-exam/${classData.teacher.teacherId}/${classData.classId}`}><img src="/view-icon.png" alt="view" className='img-fluid'/> Visualizza il corso</Link></li>
                                }
                              </ul>
                            </Col>
                          </Row>
                        </Card.Header>
                        <Accordion.Collapse eventKey={`${index}`}>
                          <>
                        <div>
                        { (this.state.showUpdate && this.state.classId == classData.classId) && (
                            <Formik >
                              <Form>
                              <div className="form-group">
                                <label className="form-label"><b>Nome della classe</b></label>
                                <Field type="text" required={[true, 'Inserisci il nome della classe']} name="name" className="form-control" defaultValue={this.state.name} onChange={(event) => {this.setState({name: event.target.value});}}/>
                              </div>
                              <div className="form-group">
                                <label className="form-label"><b>Insegnante</b></label><br/>
                                <select name="teacherId" onChange={(event) => {this.setState({teacherId: event.target.value});}}>
                                    {teachers.map((obj) => (
                                    <option value={obj.teacherId}>{obj.surname} {obj.name}</option>
                                    ))}
                                </select><br></br>
                              </div>
                              <div className="form-group">
                                <label className="form-label"><b>Numero di studenti</b></label>
                                <Field type="number" required={[true, 'Inserisci il nome della classe']} name="studentNum" className="form-control" defaultValue={this.state.studentNum} onChange={(event) => { this.checkNumber(event.target.value);}}/>
                              </div>
                              <div className="form-group">
                                <Row className="tchr-exam-row0">
                                  <Col xs={12} sm={12} className="text-right">
                                    <>
                                      <button className="btn btn-reset d-inline-block" style={{marginRight: '3px' }} onClick={this.discardForm.bind(this)}>Annulla</button>
                                      <button className="btn btn-default d-inline-block" onClick={this.handleUpdate.bind(this)}>{this.props.loading && <span className="spinner-border spinner-border-sm mr-1"></span>}Salva</button>
                                    </>
                                  </Col>
                                </Row>
                              </div>
                              </Form>
                            </Formik>
                        )}
                        </div>
                           <h5><b>STUDENTI</b></h5>
                          <Table responsive bordered>
                            <tbody>
                              {classData.students.map((studentData, ind) =>
                                <tr key={ind}>
                                  <td>{studentData.name}</td>
                                  <td>{studentData.code}</td>
                                  <td>{studentData.user && studentData.user.last_login ? <>Ultimo accesso {Moment(studentData.user.last_login).format('DD/MM/YYYY')}</> : "Nessun accesso"} </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          </>
                        </Accordion.Collapse>
                      </Card>
                    )}
                </>
              }
            </Accordion>
          </div>
        </div>
        <CSVLink download="Students Result.csv" data={this.studentResults()} className="hidden" target="_blank" ref={this.csvLink}></CSVLink>
      </div>
    );
  }
}
const CallApi = async (cb, params) => {
  await cb(...params);
}
const mapStateToProps = state => ({
  classes: state.classes,
  school: state.school,
  teachers: state.teachers,
  user: state.userAuth.user,
  studentsResult: state.studentsResult
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getClasses: (schoolId) => dispatch(classActions.getClasses(schoolId)),
    newClass: (schoolId, teacherId, name, studentNum) => dispatch(classActions.newClass(schoolId, teacherId, name, studentNum)),
    updateClass: (classId, teacherId, name, studentNum) => dispatch(classActions.updateClass(classId, teacherId, name, studentNum)),
    deleteClass: (classId) => dispatch(classActions.deleteClass(classId)),
    getStudentsResultData: (classId, done) => dispatch(classActions.getStudentsResultData(classId, done))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage)
