import { schoolConstants } from '../_constants';

export function schools(state = {}, action) {
    switch (action.type) {
        case schoolConstants.SCHOOL_REQUEST:
            return {
                loading: true
            };
        case schoolConstants.SCHOOL_SUCCESS:
            return {
                items: action.schools
            };
        case schoolConstants.SCHOOL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function sectionHeader(state = {}, action) {
    switch (action.type) {
        case schoolConstants.SECTION_HEADER_REQUEST:
            return {
                loading: true
            };
        case schoolConstants.SECTION_HEADER_SUCCESS:
            return {
                items: action.sectionHeader
            };
        case schoolConstants.SECTION_HEADER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function schoolHeader(state = {}, action) {
    switch (action.type) {
        case schoolConstants.SCHOOL_HEADER_REQUEST:
            return {
                loading: true
            };
        case schoolConstants.SCHOOL_HEADER_SUCCESS:
            return {
                items: action.classHeader
            };
        case schoolConstants.SCHOOL_HEADER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function sectionData(state = {}, action) {
    switch (action.type) {
        case schoolConstants.SECTION_DATA_REQUEST:
            return {
                loading: true
            };
        case schoolConstants.SECTION_DATA_SUCCESS:
            return {
                items: action.sectionData
            };
        case schoolConstants.SECTION_DATA_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}